import React, { useState, useEffect } from "react";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// react plugin used to create charts
import * as XLSX from "xlsx";

import { Line } from "react-chartjs-2";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Input } from "@windmill/react-ui";
import SearchIcon from "@mui/icons-material/Search";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";
import Swal from "sweetalert2";
import DownloadIcon from "@mui/icons-material/Download";
// core components
import BoltIcon from "@mui/icons-material/Bolt";
function Dashboard(props) {
  const [bigChartData, setbigChartData] = useState("data1");
  const [bigChartData2, setbigChartData2] = useState("data1");
  const [bigChartData3, setbigChartData3] = useState("data1");
  const [records, setRecords] = useState([]);
  const [records2, setRecords2] = useState([]);
  const [records3, setRecords3] = useState([]);
  const [records4, setRecords4] = useState([]);
  const [time, setTime] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    fethData();
  }, []);
  const fethData = async () => {
    await axios
      .get("https://node.baterybalencing.com/getLastSensor")
      .then((res) => {
        console.log(res.data.data);
        setRecords(res.data.data);
      })
      .catch((err) => console.log(err));
    await axios
      .get("https://node.baterybalencing.com/findAllGraph1")
      .then((res) => {
        console.log(res.data.data);
        let arr7 = [];
        for (let i = 0; i < res.data.data.length; i++) {
          const element = res.data.data[i];
          const dateObj = new Date(element.createdAt);

          // Format the date using toLocaleString
          const formattedDate = dateObj.toLocaleString();

          console.log(formattedDate);
          const split = formattedDate.split(" ");
          console.log(split);

          const waktu = `${split[0]} ${split[1]}`;
          arr7.push(waktu);
        }
        setTime(arr7);
        setRecords2(res.data.data);
      })
      .catch((err) => console.log(err));
    await axios
      .get("https://node.baterybalencing.com/findAllGraph2")
      .then((res) => {
        console.log(res.data.data);
        setRecords3(res.data.data);
      })
      .catch((err) => console.log(err));
    await axios
      .get("https://node.baterybalencing.com/findAllGraph3")
      .then((res) => {
        console.log(res.data.data);
        setRecords4(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  setInterval(fethData, 100000);

  const setBgChartData = (name) => {
    setbigChartData(name);
  };
  const setBgChartData2 = (name) => {
    setbigChartData2(name);
  };
  const setBgChartData3 = (name) => {
    setbigChartData3(name);
  };
  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
      xAxes: {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    },
  };

  let chartExample1 = {
    data1: (canvas) => {
      // let ctx = canvas.getContext("2d");

      return {
        labels: time,
        datasets: [
          {
            label: "Voltage 1",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#BF1818",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#BF1818",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#BF1818",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records2.map((data) => data.V1),
          },
          {
            label: "Voltage 2",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records2.map((data) => data.V2),
          },
          {
            label: "Voltage 3",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#00ff40",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00ff40",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00ff40",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records2.map((data) => data.V3),
          },
          {
            label: "Voltage 4",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#ffff00",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ffff00",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ffff00",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records2.map((data) => data.V4),
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Voltage 1",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#BF1818",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#BF1818",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#BF1818",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records2.map((data) => data.V1),
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Voltage 2",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records2.map((data) => data.V2),
          },
        ],
      };
    },
    data4: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Voltage 3",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#00ff40",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00ff40",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00ff40",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records2.map((data) => data.V3),
          },
        ],
      };
    },
    data5: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Voltage 4",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#ffff00",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ffff00",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ffff00",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records2.map((data) => data.V4),
          },
        ],
      };
    },
    options: chart1_2_options,
  };
  let chartExample2 = {
    data1: (canvas) => {
      // let ctx = canvas.getContext("2d");

      return {
        labels: time,
        datasets: [
          {
            label: "Arus 1",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#BF1818",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#BF1818",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#BF1818",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records3.map((data) => data.I1),
          },
          {
            label: "Arus 2",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records3.map((data) => data.I2),
          },
          {
            label: "Arus 3",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#00ff40",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00ff40",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00ff40",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records3.map((data) => data.I3),
          },
          {
            label: "Arus 4",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#ffff00",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ffff00",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ffff00",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records3.map((data) => data.I4),
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Arus 1",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#BF1818",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#BF1818",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#BF1818",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records3.map((data) => data.I1),
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Arus 2",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records3.map((data) => data.I2),
          },
        ],
      };
    },
    data4: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Arus 3",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#00ff40",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00ff40",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00ff40",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records3.map((data) => data.I3),
          },
        ],
      };
    },
    data5: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Arus 4",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#ffff00",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ffff00",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ffff00",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records3.map((data) => data.I4),
          },
        ],
      };
    },
    options: chart1_2_options,
  };
  let chartExample3 = {
    data1: (canvas) => {
      // let ctx = canvas.getContext("2d");

      return {
        labels: time,
        datasets: [
          {
            label: "Voltage SCC",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records4.map((data) => data.VSCC),
          },
          {
            label: "ArusSCC",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#00ff40",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00ff40",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00ff40",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records4.map((data) => data.ISCC),
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Voltage SCC",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records4.map((data) => data.VSCC),
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: time,
        datasets: [
          {
            label: "Arus SCC",
            fill: true,
            // backgroundColor: gradientStroke,
            borderColor: "#00ff40",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00ff40",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00ff40",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: records4.map((data) => data.ISCC),
          },
        ],
      };
    },
    options: chart1_2_options,
  };

  const onClick = () => {
    console.log(startDate);
    console.log(endDate);
    axios
      .post("https://node.baterybalencing.com/getSensor1", {
        startDate,
        endDate,
      })
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length > 0) {
          // setRecords(res.data.data);
          console.log("oke");
          Swal.fire({
            icon: "success",
            title: "Berhasil menemukan data sensor",
            showConfirmButton: false,
            timer: 1500,
          });
          let arr7 = [];
          for (let i = 0; i < res.data.data.length; i++) {
            const element = res.data.data[i];
            const dateObj = new Date(element.createdAt);

            // Format the date using toLocaleString
            const formattedDate = dateObj.toLocaleString();

            console.log(formattedDate);
            const split = formattedDate.split(" ");
            console.log(split);

            const waktu = `${split[0]} ${split[1]}`;
            arr7.push(waktu);
          }
          setTime(arr7);
          setRecords2(res.data.data);
        } else {
          setRecords2([]);
          setTime([]);
          Swal.fire({
            icon: "error",
            title: "Gagal menemukan data sensor",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const onClick2 = () => {
    console.log(startDate);
    console.log(endDate);
    axios
      .post("https://node.baterybalencing.com/getSensor2 ", {
        startDate,
        endDate,
      })
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length > 0) {
          // setRecords(res.data.data);
          console.log("oke");
          Swal.fire({
            icon: "success",
            title: "Berhasil menemukan data sensor",
            showConfirmButton: false,
            timer: 1500,
          });
          let arr7 = [];
          for (let i = 0; i < res.data.data.length; i++) {
            const element = res.data.data[i];
            const dateObj = new Date(element.createdAt);

            // Format the date using toLocaleString
            const formattedDate = dateObj.toLocaleString();

            console.log(formattedDate);
            const split = formattedDate.split(" ");
            console.log(split);

            const waktu = `${split[0]} ${split[1]}`;
            arr7.push(waktu);
          }
          setTime(arr7);
          setRecords3(res.data.data);
        } else {
          setRecords3([]);
          setTime([]);
          Swal.fire({
            icon: "error",
            title: "Gagal menemukan data sensor",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const onClick3 = () => {
    console.log(startDate);
    console.log(endDate);
    axios
      .post("https://node.baterybalencing.com/getSensor3", {
        startDate,
        endDate,
      })
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length > 0) {
          // setRecords(res.data.data);
          console.log("oke");
          Swal.fire({
            icon: "success",
            title: "Berhasil menemukan data sensor",
            showConfirmButton: false,
            timer: 1500,
          });
          let arr7 = [];
          for (let i = 0; i < res.data.data.length; i++) {
            const element = res.data.data[i];
            const dateObj = new Date(element.createdAt);

            // Format the date using toLocaleString
            const formattedDate = dateObj.toLocaleString();

            console.log(formattedDate);
            const split = formattedDate.split(" ");
            console.log(split);

            const waktu = `${split[0]} ${split[1]}`;
            arr7.push(waktu);
          }
          setTime(arr7);
          setRecords4(res.data.data);
        } else {
          setRecords4([]);
          setTime([]);
          Swal.fire({
            icon: "error",
            title: "Gagal menemukan data sensor",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const onClickCsv = () => {
    let arr = [];
    if (bigChartData === "data1") {
      for (let i = 0; i < records2.length; i++) {
        const element = records2[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          V1: element.V1,
          V2: element.V2,
          V3: element.V3,
          V4: element.V4,
          createdAt: waktu,
        });
      }
    } else if (bigChartData === "data2") {
      for (let i = 0; i < records2.length; i++) {
        const element = records2[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          V1: element.V1,
          createdAt: waktu,
        });
      }
    } else if (bigChartData === "data3") {
      for (let i = 0; i < records2.length; i++) {
        const element = records2[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          V2: element.V2,
          createdAt: waktu,
        });
      }
    } else if (bigChartData === "data4") {
      for (let i = 0; i < records2.length; i++) {
        const element = records2[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          V3: element.V3,
          createdAt: waktu,
        });
      }
    } else if (bigChartData === "data5") {
      for (let i = 0; i < records2.length; i++) {
        const element = records2[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          V4: element.V4,
          createdAt: waktu,
        });
      }
    }
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;
    XLSX.utils.book_append_sheet(wb, ws, "My Sheet1");
    XLSX.writeFile(wb, `Rekap Battery ${today}.xlsx`);
  };
  const onClickCsv2 = () => {
    let arr = [];
    if (bigChartData2 === "data1") {
      for (let i = 0; i < records3.length; i++) {
        const element = records3[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          I1: element.I1,
          I2: element.I2,
          I3: element.I3,
          I4: element.I4,
          createdAt: waktu,
        });
      }
    } else if (bigChartData2 === "data2") {
      for (let i = 0; i < records3.length; i++) {
        const element = records3[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          I1: element.I1,
          createdAt: waktu,
        });
      }
    } else if (bigChartData2 === "data3") {
      for (let i = 0; i < records3.length; i++) {
        const element = records3[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          I2: element.I2,
          createdAt: waktu,
        });
      }
    } else if (bigChartData2 === "data4") {
      for (let i = 0; i < records3.length; i++) {
        const element = records3[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          I3: element.I3,
          createdAt: waktu,
        });
      }
    } else if (bigChartData2 === "data5") {
      for (let i = 0; i < records3.length; i++) {
        const element = records3[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          I4: element.I4,
          createdAt: waktu,
        });
      }
    }
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;
    XLSX.utils.book_append_sheet(wb, ws, "My Sheet1");
    XLSX.writeFile(wb, `Rekap Battery ${today}.xlsx`);
  };
  const onClickCsv3 = () => {
    let arr = [];
    if (bigChartData3 === "data1") {
      for (let i = 0; i < records4.length; i++) {
        const element = records4[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          VSCC: element.VSCC,
          ISCC: element.ISCC,
          createdAt: waktu,
        });
      }
    } else if (bigChartData3 === "data2") {
      for (let i = 0; i < records4.length; i++) {
        const element = records4[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          VSCC: element.VSCC,
          createdAt: waktu,
        });
      }
    } else if (bigChartData3 === "data3") {
      for (let i = 0; i < records4.length; i++) {
        const element = records4[i];
        const dateObj = new Date(element.createdAt);

        const formattedDate = dateObj.toLocaleString();

        console.log(formattedDate);
        const split = formattedDate.split(" ");
        console.log(split);

        const waktu = `${split[0]} ${split[1]}`;
        arr.push({
          ISCC: element.ISCC,
          createdAt: waktu,
        });
      }
    }
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;
    XLSX.utils.book_append_sheet(wb, ws, "My Sheet1");
    XLSX.writeFile(wb, `Rekap Battery ${today}.xlsx`);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">VOLTAGE</h6>
                    <CardTitle tag="h2">VOLTAGE 1</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.V1} V</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">VOLTAGE</h6>
                    <CardTitle tag="h2">VOLTAGE 2</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.V2} V</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">VOLTAGE</h6>
                    <CardTitle tag="h2">VOLTAGE 3</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.V3} V</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">ARUS</h6>
                    <CardTitle tag="h2">ARUS 1</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.I1} A</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">ARUS</h6>
                    <CardTitle tag="h2">ARUS 2</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.I2} A</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">ARUS</h6>
                    <CardTitle tag="h2">ARUS 3</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.I3} A</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">VOLTAGE</h6>
                    <CardTitle tag="h2">VOLTAGE 4</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.V4} V</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">VOLTAGE</h6>
                    <CardTitle tag="h2">VOLTAGE SCC</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.VSCC} V</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">ARUS</h6>
                    <CardTitle tag="h2">ARUS 4</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.I4} A</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="4">
            <Card className="card-chart ">
              <CardHeader>
                <Row>
                  <Col className="text-center" sm="12">
                    <h6 className="card-category">ARUS</h6>
                    <CardTitle tag="h2">ARUS SCC</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="">
                <Row>
                  <Col className="text-center" sm="12">
                    <CardTitle tag="h1">{records.ISCC} A</CardTitle>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="1">
                    <h6 className="card-category">Graphic 1</h6>
                    <CardTitle tag="h4">Voltage</CardTitle>
                  </Col>
                  <Col className="text-left" sm="8">
                    <span className="mr-2">Start Date</span>
                    <Input
                      className="mt-1 mr-2 bg-transparent"
                      placeholder="Jane Doe"
                      type="date"
                      style={{ color: "#1f8ef1" }}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <span className="mr-2">End Date</span>
                    <Input
                      className="mt-1 bg-transparent mr-2"
                      placeholder="Jane Doe"
                      type="date"
                      style={{ color: "#1f8ef1" }}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                    <Button
                      color="info"
                      id="1"
                      size="sm"
                      tag="label"
                      className={classNames("btn-simple")}
                      onClick={onClick}
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        CARI
                      </span>
                      <span className="d-block d-sm-none">
                        <SearchIcon />
                      </span>{" "}
                    </Button>
                  </Col>
                  <Col sm="3">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          All
                        </span>
                        <span className="d-block d-sm-none">
                          <BoltIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Volt1
                        </span>
                        <span className="d-block d-sm-none">
                          <BoltIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data3",
                        })}
                        onClick={() => setBgChartData("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Volt2
                        </span>
                        <span className="d-block d-sm-none">
                          <BoltIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data4",
                        })}
                        onClick={() => setBgChartData("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Volt3
                        </span>
                        <span className="d-block d-sm-none">
                          <BoltIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data5",
                        })}
                        onClick={() => setBgChartData("data5")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Volt4
                        </span>
                        <span className="d-block d-sm-none">
                          <BoltIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple")}
                        onClick={onClickCsv}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Download
                        </span>
                        <span className="d-block d-sm-none">
                          <DownloadIcon />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="1">
                    <h6 className="card-category">Graphic 2</h6>
                    <CardTitle tag="h4">Arus</CardTitle>
                  </Col>{" "}
                  <Col className="text-left" sm="8">
                    <span className="mr-2">Start Date</span>
                    <Input
                      className="mt-1 mr-2 bg-transparent"
                      placeholder="Jane Doe"
                      type="date"
                      style={{ color: "#1f8ef1" }}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <span className="mr-2">End Date</span>
                    <Input
                      className="mt-1 bg-transparent mr-2"
                      placeholder="Jane Doe"
                      type="date"
                      style={{ color: "#1f8ef1" }}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                    <Button
                      color="info"
                      id="1"
                      size="sm"
                      tag="label"
                      className={classNames("btn-simple")}
                      onClick={onClick2}
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        CARI
                      </span>
                      <span className="d-block d-sm-none">
                        <SearchIcon />
                      </span>{" "}
                    </Button>
                  </Col>
                  <Col sm="3">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData2 === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData2("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          All
                        </span>
                        <span className="d-block d-sm-none">
                          <FlashAutoIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData2 === "data2",
                        })}
                        onClick={() => setBgChartData2("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Arus1
                        </span>
                        <span className="d-block d-sm-none">
                          <FlashAutoIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData2 === "data3",
                        })}
                        onClick={() => setBgChartData2("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Arus2
                        </span>
                        <span className="d-block d-sm-none">
                          <FlashAutoIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData2 === "data4",
                        })}
                        onClick={() => setBgChartData2("data4")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Arus3
                        </span>
                        <span className="d-block d-sm-none">
                          <FlashAutoIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData2 === "data5",
                        })}
                        onClick={() => setBgChartData2("data5")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Arus4
                        </span>
                        <span className="d-block d-sm-none">
                          <FlashAutoIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple")}
                        onClick={onClickCsv2}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Download
                        </span>
                        <span className="d-block d-sm-none">
                          <DownloadIcon />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample2[bigChartData2]}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="1">
                    <h6 className="card-category">Graphic 3</h6>
                    <CardTitle tag="h4">Voltage</CardTitle>
                  </Col>
                  <Col className="text-left" sm="8">
                    <span className="mr-1">Start Date</span>
                    <Input
                      className="mt-1 mr-1 bg-transparent"
                      placeholder="Jane Doe"
                      type="date"
                      style={{ color: "#1f8ef1" }}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <span className="mr-1">End Date</span>
                    <Input
                      className="mt-1 bg-transparent mr-1"
                      placeholder="Jane Doe"
                      type="date"
                      style={{ color: "#1f8ef1" }}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                    <Button
                      color="info"
                      id="1"
                      size="sm"
                      tag="label"
                      className={classNames("btn-simple")}
                      onClick={onClick3}
                    >
                      <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        CARI
                      </span>
                      <span className="d-block d-sm-none">
                        <SearchIcon />
                      </span>{" "}
                    </Button>
                  </Col>
                  <Col sm="3">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData3 === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData3("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          All
                        </span>
                        <span className="d-block d-sm-none">
                          <BoltIcon />
                        </span>
                      </Button>

                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData3 === "data2",
                        })}
                        onClick={() => setBgChartData3("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          VoltageSCC
                        </span>
                        <span className="d-block d-sm-none">
                          <BoltIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData3 === "data3",
                        })}
                        onClick={() => setBgChartData3("data3")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          ArusSCC
                        </span>
                        <span className="d-block d-sm-none">
                          <BoltIcon />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="2"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple")}
                        onClick={onClickCsv3}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Download
                        </span>
                        <span className="d-block d-sm-none">
                          <DownloadIcon />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample3[bigChartData3]}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
