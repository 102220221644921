import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onclick = () => {
    console.log(email, password);
    const email1 = "BatteryBalencing@gmail.com";
    const password1 = "Batery123";
    if (email === email1) {
      if (password === password1) {
        Swal.fire({
          icon: "success",
          title: "Berhasil Login",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/admin/dashboard");
      } else {
        Swal.fire({
          icon: "error",
          title: "Password yang anda masukan salah",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Email yang anda masukan salah",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <MDBContainer className="my-5 gradient-form">
      <MDBRow>
        {/* d-none d-sm-block d-md-block d-lg-block d-xl-block */}
        <div className=" d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex">
          {" "}
          <MDBCol col="6" className="mb-5">
            <div className="d-flex flex-column ms-5">
              <div className="text-center">
                <img
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                  style={{ width: "185px" }}
                  alt="logo"
                />
                <h4 className="mt-1 mb-5 pb-1">
                  Selamat datang di Batery Balencing
                </h4>
              </div>

              <p>Harap login terlebih dahulu</p>

              <MDBInput
                wrapperClass="mb-4"
                label="Email address"
                id="form1"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDBInput
                wrapperClass="mb-4"
                label="Password"
                id="form2"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className="text-center pt-1 mb-5 pb-1">
                <MDBBtn
                  onClick={onclick}
                  className="mb-4 w-100 gradient-custom-2"
                >
                  Login
                </MDBBtn>
              </div>
            </div>
          </MDBCol>
          <MDBCol col="6" className="mb-5">
            <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
              <div className="text-white px-3 py-4 p-md-5 mx-md-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                <h4 class="mb-4">Tugas Akhir Batery Balencing</h4>
                <p class="small mb-0">
                  Battery balancing adalah proses untuk memastikan bahwa setiap
                  sel atau sel dalam paket baterai memiliki tegangan yang
                  seimbang. Ini penting karena ketidakseimbangan tegangan dapat
                  menyebabkan beberapa sel baterai mengalami overcharge atau
                  overdischarge, yang pada gilirannya dapat mengurangi umur
                  paket baterai secara keseluruhan atau bahkan menyebabkan
                  kegagalan baterai. PZEM (Power and Energy Meter) adalah
                  perangkat yang digunakan untuk mengukur parameter-parameter
                  listrik seperti tegangan, arus, daya, dan energi. Dalam
                  konteks pengisian dan pengeluaran baterai, PZEM dapat
                  digunakan untuk memantau tegangan dan arus baterai. Monitoring
                  melalui website dapat dilakukan dengan menggunakan
                  mikrokontroler atau perangkat IoT (Internet of Things) yang
                  terhubung ke jaringan internet. Mikrokontroler dapat mengambil
                  data dari PZEM dan mentransmisikannya ke server atau layanan
                  web yang dapat diakses melalui browser. Data tersebut kemudian
                  dapat divisualisasikan dalam bentuk grafik atau tabel untuk
                  memantau keseimbangan baterai secara langsung.
                </p>
              </div>
            </div>
          </MDBCol>
        </div>
        <div className="d-block d-sm-none">
          <MDBCol col="12" className="mb-5">
            <div className="d-flex flex-column ms-5">
              <div className="text-center">
                <img
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                  style={{ width: "185px" }}
                  alt="logo"
                />
                <h4 className="mt-1 mb-5 pb-1">
                  Selamat datang di Batery Balencing
                </h4>
              </div>

              <p>Harap login terlebih dahulu</p>

              <MDBInput
                wrapperClass="mb-4"
                label="Email address"
                id="form1"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDBInput
                wrapperClass="mb-4"
                label="Password"
                id="form2"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className="text-center pt-1 mb-5 pb-1">
                <MDBBtn
                  onClick={onclick}
                  className="mb-4 w-100 gradient-custom-2"
                >
                  Login
                </MDBBtn>
              </div>
            </div>
          </MDBCol>
        </div>
      </MDBRow>
    </MDBContainer>
  );
}

export default Login;
